import { isEmpty } from 'lodash'
import { EVENTS, NOTIFICATION_EVENT_CODE, NOTIFICATION_OBJECT_TYPE, PATHS } from '~/common/constants'
import { action, computed, event, observable, store } from '~/common/mobx.decorator'
import Bowser from 'bowser'
import { authStore, routerStore } from '~/stores'

@store()
class NotifyStore {
	@observable messages = []
	@observable doneMessages = {}

	@observable dialogs = []
	@observable doneDialogs = {}
	@observable isOpenNotification = false
	@observable isErrorInitOneSignal = false

	@computed
	get waitMessages() {
		return this.messages.filter((message) => !this.doneMessages[message.key])
	}

	@computed
	get waitDialogs() {
		return this.dialogs.filter((dialog) => !this.doneDialogs[dialog.key])
	}

	@computed
	get signal() {
		return window.OneSignal || []
	}

	@action
	open = async (message, options) => {
		const data = {
			key: `${Date.now()}_${Math.random()}`,
			message,
			options,
		}

		if (options.showDialog) {
			this.dialogs.push(data)

			return data.key
		}
		if (options?.onlyWebPush) {
			return
		}

		this.messages.push(data)
		return data.key
	}

	@action
	success = async (message, options) => {
		return this.open(message, { ...options, variant: 'success', customization: true })
	}

	@action
	error = async (message, options) => {
		return this.open(message, { ...options, variant: 'error' })
	}

	@action
	info = async (message, options) => {
		return this.open(message, { ...options, variant: 'info' })
	}

	@action
	warning = async (message, options) => {
		return this.open(message, { ...options, variant: 'warning' })
	}

	@action
	closeMessage = async (key) => {
		this.doneMessages[key] = true
	}

	@action
	closeDialog = async (key) => {
		this.doneDialogs[key] = true
	}

	@event(EVENTS.notifyStore.fireError)
	handleError({ payload }) {
		this.error(payload?.message)
	}

	@action
	listenEventOpenNotiClick = async () => {
		await this.signal.on('notificationDisplay', (event) => {
			console.log('notificationDisplay', event)
		})
		await this.signal.on('customPromptClick', (event) => {
			console.log('customPromptClick', event)
		})

		await this.signal.addListenerForNotificationOpened((event) => {
			const eventCode = event?.data?.event
			const objectType = event?.data?.objectType
			const objectId = event?.data?.objectId
			const additionalData = event?.data?.additionalData
			if (
				[
					NOTIFICATION_EVENT_CODE.credential_incompleted,
					NOTIFICATION_EVENT_CODE.credential_new_request,
					NOTIFICATION_EVENT_CODE.credential_expired_soon,
				].includes(eventCode)
			) {
				void routerStore.goPage(PATHS.care.credentials)
			}
			if ([NOTIFICATION_EVENT_CODE.job_matches].includes(eventCode)) {
				void routerStore.goPage(PATHS.care.suggested_jobs)
			}

			if ([NOTIFICATION_OBJECT_TYPE.job].includes(objectType)) {
				void routerStore.goPage(`${PATHS.care.jobs}/${objectId}`)
			}
			if ([NOTIFICATION_EVENT_CODE.job_alert].includes(eventCode)) {
				void routerStore.goPage(`${PATHS.care.search}?saveSearchId=${objectId}`)
			}
			if ([NOTIFICATION_EVENT_CODE.job_rating].includes(eventCode)) {
				void routerStore.goPage(`${PATHS.care.jobs}/${additionalData?.jobId}?isShowRatingForm=true`)
			}
			if ([NOTIFICATION_EVENT_CODE.assignment_check_out].includes(eventCode)) {
				void routerStore.goPage(`${PATHS.care.jobs}/${additionalData?.jobId}`)
			}
			if ([NOTIFICATION_EVENT_CODE.timecard_submission].includes(eventCode)) {
				void routerStore.goPage(PATHS.care.timecards)
			}
		})
	}

	@action
	initOneSignal = async () => {
		if (!isEmpty(process.env.REACT_APP_COMPANY_ID)) {
			await this.signal
				.init({
					appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
					autoRegister: false,
					autoResubscribe: true,
					notifyButton: {
						enable: true,
						prenotify: true,
					},
					allowLocalhostAsSecureOrigin: true,
				})
				.then(() => {
					this.isErrorInitOneSignal = false
				})
				.catch((error) => {
					console.error(error)
					this.isErrorInitOneSignal = true
				})

			if (this.isErrorInitOneSignal) {
				return
			}
			await this.signal.addListenerForNotificationOpened((event) => {
				console.log('Here notification opened', event)

				const eventCode = event?.data?.event
				const objectType = event?.data?.objectType
				const objectId = event?.data?.objectId
				const additionalData = event?.data?.additionalData

				if (
					[
						NOTIFICATION_EVENT_CODE.credential_incompleted,
						NOTIFICATION_EVENT_CODE.credential_new_request,
						NOTIFICATION_EVENT_CODE.credential_expired_soon,
					].includes(eventCode)
				) {
					void routerStore.goPage(PATHS.care.credentials)
				}
				if ([NOTIFICATION_EVENT_CODE.job_matches].includes(eventCode)) {
					void routerStore.goPage(PATHS.care.suggested_jobs)
				}

				if ([NOTIFICATION_OBJECT_TYPE.job].includes(objectType)) {
					void routerStore.goPage(`${PATHS.care.jobs}/${objectId}`)
				}
				if ([NOTIFICATION_EVENT_CODE.job_alert].includes(eventCode)) {
					void routerStore.goPage(`${PATHS.care.search}?saveSearchId=${objectId}`)
				}
				if ([NOTIFICATION_EVENT_CODE.job_rating].includes(eventCode)) {
					void routerStore.goPage(`${PATHS.care.jobs}/${additionalData?.jobId}?isShowRatingForm=true`)
				}
				if ([NOTIFICATION_EVENT_CODE.assignment_check_out].includes(eventCode)) {
					void routerStore.goPage(`${PATHS.care.jobs}/${additionalData?.jobId}`)
				}
				if ([NOTIFICATION_EVENT_CODE.job_applicant_status_changed].includes(eventCode)) {
					void routerStore.goPage(`${PATHS.care.jobs}/${additionalData?.jobId}`)
				}
				if ([NOTIFICATION_EVENT_CODE.timecard_submission].includes(eventCode)) {
					void routerStore.goPage(PATHS.care.timecards)
				}
			})
		}
	}

	@action
	sendInfo = async () => {
		const { notificationChannel, workerEmail, id } = authStore

		if (this.isErrorInitOneSignal) {
			return
		}

		this.signal.push(async () => {
			void this.signal.setExternalUserId(notificationChannel)

			if (!isEmpty(process.env.REACT_APP_ONESIGNAL_APP_ID)) {
				const browser = Bowser.getParser(window.navigator.userAgent)
				void this.signal.getSubscription((notOptedOut) => {
					if (notOptedOut) {
						this.signal.setSubscription(true)
						this.signal.registerForPushNotifications()
					}
				})

				void this.signal
					.sendTags({
						// companyId: process.env.REACT_APP_COMPANY_ID,
						// userType: 'Worker',
						browser: browser.getBrowser(),
						workerId: id,
						workerEmail: workerEmail,
					})
					.then((tagsSent) => {
						console.warn('tagsSent: ', tagsSent)
					})
					.catch((e) => {
						console.warn('e: ', e)
					})
			}
		})
	}

	@action
	unInitOneSignal = async () => {
		await this.signal.removeExternalUserId()
	}
}

export const notifyStore = new NotifyStore()
