import { makeStyles } from '@material-ui/core'
import { css, createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  html {
    -webkit-transform: unset !important;
    min-height: 100vh;
  }
  body {
    color: ${({ theme }) => theme.palette.text.primary};
    background-color: ${({ theme }) => theme.palette.background.default} !important;
    overflow-x: hidden;
    min-height: 100vh;
  }
  .MuiAppBar-root{
    z-index: 200;
  }
  .Mui-checked.MuiCheckbox-colorSecondary.Mui-disabled{
    color :  #5BD5FF;

  }
  :focus {
    outline-color: transparent !important;
  }

  .mobile-open .custom-mobile-menu{
    z-index: 2;
  }


  .body-wrapper{
    overflow: initial !important;
  }


  @media (max-width: 500px) {

    .MuiStepper-root {
      padding: 0 !important;
    }
  }


  @media (max-width: 956px) {
    .rwc-embed {
      bottom: 76px !important;
    }
    .dialog-refer {
      bottom: 150px !important;
    }
  }
  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:focus {
outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  .grecaptcha-badge {
    display: none;
  }

  .pac-container {
    z-index: 3000;
  }

  .MuiPickersCalendarHeader-monthTitleContainer {
    align-items: center;
  }

  body {
    margin: 0;
    line-height: normal;
  }
  :root {
    /* fonts */
    --tj-typo-body-1-regular-16-22-03px: Manrope;

    /* font sizes */
    --tj-typo-body-1-regular-16-22-03px-size: 16px;
    --tj-typo-body-3-size: 12px;
    --tj-body-2-regular-14-20-02px-size: 14px;
    --font-size-21xl: 40px;

    /* Colors */
    --tj-color-background-app-light: #fff;
    --tj-hyperlink-medium: #2c6eee;
    --tj-color-content-light: #e6e6e6;
    --tj-color-content-dark: #7e7e80;
    --tj-color-background-app-dark: #000;
    --tj-content-very-dark: #333335;
    --tj-color-primary-main: #00AAE5;

    /* Gaps */
    --gap-5xl: 24px;
    --gap-base: 16px;
    --gap-5xs: 8px;
    --gap-9xs: 4px;

    /* Paddings */
    --padding-xs: 12px;
    --padding-5xl: 24px;

    /* border radiuses */
    --br-29xl: 48px;
    --br-lg: 18px;
  }
`

export const linearProgressStyle = css`
	position: fixed;
	top: 0;
	width: 100vw;
	height: 3px;
	z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`

export const useStyles = makeStyles((theme) => ({
	root: {
		'& div[class*="contentRoot"]': {
			borderRadius: '1rem',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 600,
		},
	},
}))
