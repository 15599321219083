import { observer } from 'mobx-react-lite'
import { useCallback, useState } from 'react'
import { authStore, routerStore } from './stores'
import { useInterval } from 'react-use'
import { useDebounceEffect } from 'ahooks'
import qs from 'query-string'

function formatUrl(path, params) {
	for (const key in params) {
		if (params.hasOwnProperty(key)) {
			path = path.replace(`{${key}}`, params[key])
		}
	}

	return path
}

const navigateTo = (action, args = {}) => {
	const queryString = qs.stringify({ ...args, action, from: 'ai' })

	switch (action) {
		case 'search_jobs':
			routerStore.push(`/search?${queryString}`)
			break
		case 'timecard':
			routerStore.push(`/timecards?${queryString}`)
			break
		case 'onboarding':
			routerStore.push(`/activation?${queryString}`)
			break
		case 'create_account_successfully':
			routerStore.push(`/signin?${queryString}`)
			break
		default:
			break
	}
}

export const Assistant = observer(() => {
	const { token } = authStore

	const [instance, setInstance] = useState(window.OpusAssistant)

	const onReceiveMessage = useCallback((data) => {
		navigateTo(data?.action_type, data?.action_params)
		// const path = PATHS[data.action_type]

		// if (path) {
		// 	const url = formatUrl(path, data.action_params)
		// 	// console.log('url', url)
		// 	routerStore.push(url)

		//     navigateTo(response?.data?.action_type, response?.data?.arguments)
		// }
	}, [])

	useInterval(
		() => {
			if (window.OpusAssistant) {
				setInstance(window.OpusAssistant)
			}
		},
		instance ? null : 1000
	)

	useDebounceEffect(
		() => {
			if (instance) {
				instance.init({ baseUrl: 'https://opus-ai-assistant-qa.opusasia.io', token, onReceiveMessage })
			}
		},
		[instance, token, onReceiveMessage],
		{ wait: 1000, leading: false, trailing: true }
	)

	return null
})
